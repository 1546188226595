import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { getText } from '../../../utils/cms_copy_provider';
import isApp from '../../../utils/isApp';
import sendTicketEntryMetrics from '../../../utils/sendTicketEntryMetrics';

export const EntryForm = ({ promotion_id, promotion, appUrl, createfunc, ticket, entry, actions }) => {
    const formUrl = appUrl + '/entry.php';

    const initialState = {
        ticket1_1_2: '',
        ticket1_1_1: '',
        entry_method: 'manual',
        form_submitted: '1',
        'entry-submit': 'SUBMIT+ENTRY',
        promotion: promotion,
    };

    const [formState, setFormState] = useState(initialState);

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        clearIfSuccess();
    }, [ticket]);

    // fire the submission
    const handleSubmit = async event => {
        setDisabled(true);
        event.preventDefault();

        let wasScanned = formState.entry_method === 'scan' ? true : false;
        sendTicketEntryMetrics(isApp(), wasScanned, ticket.success);

        await createfunc(formState);

        setDisabled(false);
    };

    // if the form field changes, we will change the value in the store and
    // also check if the next field must be focused
    const handleChange = function(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormState({ ...formState, [name]: value });

        autotab(target, target.dataset.next);
    };

    // if the form submit was a success, we clear the form
    const clearIfSuccess = async () => {
        if (ticket.success) {
            // UNCOMMENT FOR GAME MODAL on URL
            //await actions.gameActions.getGame(entry.last_entry);

            setFormState(initialState);
            actions.scratchTicketActions.reset();
            navigate('/post_claim', { state: { returnURL: '/enter_scratch' } });
        }
    };

    // rehash of old autotab functionality from PA
    const autotab = (current, nex) => {
        var next = document.getElementById(nex);

        if (!!nex && !!current && current.getAttribute && current.value.length === parseInt(current.getAttribute('maxlength'))) {
            next.focus();
        }
    };

    return (
        <div id='form-target' className='entry-form scratch-form'>
            <form
                name='ticket_entry'
                id='ticket_entry'
                action={formUrl}
                disabled={disabled}
                method='post'
                encType='application/x-www-form-urlencoded'
                onSubmit={handleSubmit}>
                <div className='row entry-fields'>
                    <div className='col col-md-8 offset-md-2'>
                        <div className='mt-2 mb-4'>
                            <div className='form-group'>
                                <div className='col-12 label-text-override' label-text-override={getText('enter_scratch_input_entrynumber')}>
                                    <input
                                        type='text'
                                        name='ticket1_1_2'
                                        id='ticket1_1_2'
                                        data-next='ticket1_1_1'
                                        value={formState.ticket1_1_2}
                                        onChange={handleChange}
                                        size='12'
                                        maxLength='12'
                                        className='form-control entry-input'
                                    />
                                </div>
                                <div className='col-12 mt-2 label-text-override' label-text-override={getText('enter_scratch_input_ticketnumber')}>
                                    <input
                                        type='text'
                                        name='ticket1_1_1'
                                        id='ticket1_1_1'
                                        value={formState.ticket1_1_1}
                                        onChange={handleChange}
                                        size='14'
                                        maxLength='14'
                                        className='form-control entry-input'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='entry-submit col col-md-8 offset-md-2'>
                        <input id='entry-submit' type='submit' name='submit' value={getText('enter_scratch_btn_enterticket')} className='entry-submit' />
                    </div>
                </div>
                <input type='hidden' id='entry_method' name='entry_method' value={formState.entry_method} />
                <input type='hidden' id='form_submitted' name="form_submitted: '1'" value={formState.form_submitted} />
            </form>
        </div>
    );
};

export default EntryForm;
