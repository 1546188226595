import React from 'react';
import './flippable.scss';

export default class Flippable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            flipped: false,
        };

        this.toggleFlipped = this.toggleFlipped.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    toggleFlipped() {
        this.setState({
            flipped: !this.state.flipped,
        });
    }

    handleKeyDown(ev) {
        // user presses enter
        if (ev.keyCode == 13) {
            this.toggleFlipped();
        }
    }

    render() {
        const { flipped } = this.state;
        var active = flipped ? 'active' : '';

        return (
            <div
                role="button"
                tabIndex="0"
                className="flippable"
                onClick={this.toggleFlipped}
                onKeyDown={this.handleKeyDown}
            >
                <span className="sr-only">{this.props.srtext}</span>
                <div className={'square-flip ' + active} role="presentation">
                    <div
                        className="square square1"
                        style={{
                            backgroundImage: `url(${this.props.frontImg})`,
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                    <div
                        className="square square2"
                        style={{
                            backgroundImage: `url(${this.props.backImg})`,
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                </div>

                <div className={'linkText ' + (!flipped ? 'hidden' : '')}>
                    {this.props.frontText}
                </div>
                <div className={'linkText ' + (flipped ? 'hidden' : '')}>
                    {this.props.backText}
                </div>
            </div>
        );
    }
}
